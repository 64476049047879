import { ReactNode } from "react"

export const HeaderContainer: React.FC<{ className?: string; children: ReactNode; isHeaderWithShadow?: boolean }> = ({
  children,
  className,
  isHeaderWithShadow,
}) => {
  return (
    <div
      data-testid="header"
      css={(theme) => ({
        width: "100%",
        backgroundColor: theme.colors.grey0,
        alignItems: "center",
        display: "flex",
        flexShrink: 0,
        height: 60,
        justifyContent: "center",
        position: "sticky",
        top: 0,
        [theme.mqs.tablet]: {
          height: 80,
        },
        zIndex: 200,
        boxShadow: isHeaderWithShadow ? "0px 1px 1px 0px rgba(51, 68, 84, 0.1)" : "",
      })}
      className={className}
    >
      {/* to center and set maxWidth */}
      <div
        css={{
          width: "100%",
          alignItems: "center",
          maxWidth: 1280,
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        {children}
      </div>
    </div>
  )
}
