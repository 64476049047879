import { BodyMedium } from "@app/base-ui/Typography"
import { isAppRedirect } from "@app/lib/casavoVisitsUtils/appRedirectUtils"
import { isInAppWebView } from "@app/lib/casavoVisitsUtils/appWebViewUtils"
import { getDefaultLanguage } from "@app/lib/language"
import styled from "@emotion/styled"
import useTranslation from "next-translate/useTranslation"
import router from "next/router"
import { HeaderContainer } from "../LogoHeader/HeaderContainer"

type BackButtonHeaderProps = {
  canGoBack?: boolean
  isHeaderWithShadow?: boolean
}

export const BackButtonHeader: React.FC<BackButtonHeaderProps> = ({ canGoBack = true, isHeaderWithShadow }) => {
  const onBackClick = () => {
    window.history.back()
  }

  return (
    <BackButtonHeaderView canGoBack={canGoBack} onBackClick={onBackClick} isHeaderWithShadow={isHeaderWithShadow} />
  )
}

export const BackButtonHeaderView: React.FC<{
  canGoBack: boolean
  onBackClick: () => void
  isHeaderWithShadow?: boolean
}> = ({ canGoBack, onBackClick, isHeaderWithShadow }) => {
  const { lang, t } = useTranslation()

  const onLogoClick = () => {
    if (isInAppWebView() || isAppRedirect()) {
      return router.push("/")
    }
    return (window.location.href =
      lang === getDefaultLanguage()
        ? `${process.env.NEXT_PUBLIC_WEBSITE_URL}/`
        : `${process.env.NEXT_PUBLIC_WEBSITE_URL}/${lang}/`)
  }

  return (
    <HeaderContainer isHeaderWithShadow={isHeaderWithShadow}>
      <BackLinkContainer>
        {canGoBack && (
          <BackLink onClick={onBackClick}>
            <img src="/flow/icons/arrow-left.svg" alt={t("common:back")} />
            <BodyMedium
              css={(theme) => ({
                display: "none",
                [theme.mqs.tablet]: {
                  display: "block",
                  marginLeft: theme.spacings["sp-1"],
                },
              })}
            >
              {t("common:back")}
            </BodyMedium>
          </BackLink>
        )}
      </BackLinkContainer>
      <div css={{ flexShrink: 0 }}>
        <a onClick={onLogoClick}>
          <img alt="" aria-hidden="true" src="/flow/icons/casavo-logo.svg" />
        </a>
      </div>
      <div css={{ flex: 1 }}></div>
    </HeaderContainer>
  )
}

const BackLinkContainer = styled.div({
  flex: 1,
  display: "flex",
  alignItems: "center",
})

const BackLink = styled.a({
  display: "inline-flex",
  alignItems: "center",
  cursor: "pointer",
})
